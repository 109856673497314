import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  currentPageTitle = 'Inicio';
  pages = [

    {
      title: 'Inicio',
      url: '/home',
      icon: 'home-outline'
    },

    {
      title: 'Productos',
      url: '/servicios',
      icon: 'cart-outline'
    },
    {
      title: 'Contacto',
      url: '/contacto',
      icon: 'mail-outline' 
    }
  ];

  constructor(public platform: Platform) {
    this.hideSplash();
  }

  async hideSplash(): Promise<void> {
    try {
      await this.platform.ready()
    } catch (error) {
      console.error("Error");
    }

    const splash = document.getElementById('splash-screen')
      splash.style.opacity = '1' 
      setTimeout(() => {splash.remove() }, 300)
  }
  
}
